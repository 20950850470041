<template>
    <v-container fluid>
        <v-snackbar :timeout="3000" v-model="showSnackbar" color="red accent-4" elevation="24" center>
            {{ snackbarText }}
            <template v-slot:action>
                <v-btn color="white" text @click="showSnackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
        <v-card>
            <v-card-text>

                <v-text-field v-model="clientId" @blur="getClientInfo" label="Ügyfél azonosító" clearable>
                </v-text-field>

                <v-progress-circular v-if="clientLoading || servicesLoading" indeterminate color="primary">
                </v-progress-circular>
                <template v-if="client">
                    <v-row>
                        <v-col cols="6">
                            <h4>Ügyfél adatai:</h4>
                            <ul>
                                <li><b>Név:</b> {{ client.firstName }} {{ client.lastName }}</li>
                                <li><b>Egyenleg:</b> {{ client.accountBalance}} Ft</li>
                                <li><b>Cím:</b> {{ client.zipCode}}, {{ client.city }}, {{ client.street1}}</li>
                            </ul>

                        </v-col>
                        <v-col cols="6">
                            <h4>PPPOE adatok:</h4>
                            <ul>
                                <li v-for="pppoe, i in clientPPPOE" :key="i">
                                    {{ pppoe.value }}
                                </li>
                            </ul>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <h3>Végpontok:</h3>
                            <div style="margin-top: 10px;" v-for="service, i in clientServices" :key="i">
                                <h4>{{ service.zipCode }}, {{ service.city}}, {{ service.street1}}</h4>
                                <ul>
                                    <li><b>Csomag:</b> {{ service.name }}</li>
                                    <li><b>Szerződés:</b> {{ service.contractId }}</li>
                                    <li><b>Megjegyzés:</b> {{ service.note }}</li>
                                </ul>
                            </div>
                        </v-col>
                    </v-row>
                </template>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn style="width: 100px;" color="primary" :disabled="!client" @click="submit">Törlés</v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import { UCRMApi } from "../../api/ucrm";
// 2018057930
export default {
    name: "DeleteClient",
    data: () => ({
        clientId: null,
        client: null,
        clientLoading: false,
        servicesLoading: false,
        clientServices: [],
        showSnackbar: false,
        snackbarText: ""
    }),
    computed: {
        clientPPPOE() {
            if (this.client) {
                return this.client.attributes.filter((el) => {
                    if (el.key.startsWith("pppoe")) {
                        return el;
                    }
                });
            }
            return [];
        },
    },
    mounted: function () {
        this.$store.commit("app/setTitle", "UCRM ügyfél törlése");
    },
    methods: {
        getClientInfo: async function () {
            if (this.clientId && this.clientId.length > 0) {
                this.client = null;
                this.clientServices = [];
                const loadingInterval = setTimeout(() => {
                    this.clientLoading = true;
                }, 100);
                const servicesLoadingInterval = setTimeout(() => {
                    this.clientLoading = true;
                }, 100);

                await UCRMApi.getClient(this.clientId)
                    .then((response) => this.client = response.data)
                    .catch((err) => {
                        // Ne is próbáljuk betölteni az ügyfél végpontjait
                        clearTimeout(servicesLoadingInterval);
                        this.servicesLoading = false;
                        if (err.response.status == 404) {
                            this.showSnackbar = true;
                            this.snackbarText = "Ügyfél nem található!";
                        }
                    })
                    .finally(() => {
                        clearTimeout(loadingInterval);
                        this.clientLoading = false;
                    });
                await UCRMApi.getClientServices(this.clientId)
                    .then((response) => this.clientServices = response.data)
                    .finally(() => {
                        clearTimeout(servicesLoadingInterval);
                        this.servicesLoading = false;
                    });
            }
            else {
                this.client = null;
                this.clientServices = [];
            }
        },
        submit: function () {
            if (confirm("Biztos törölni akarod?")) {
                UCRMApi.deleteClient(this.clientId).then(() => {
                    this.client = null;
                    this.clientServices = null;
                    this.snackbarText = "Ügyfél törölve";
                    this.showSnackbar = true;
                    this.clientId = null;
                });
            }
        }
    }
};
</script>