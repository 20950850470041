import { API } from ".";

export const UCRMApi = {
  getClient: async (clientId) => {
    return await API.instance.get(`/ucrm/client/${clientId}`);
  },
  getClientServices: async (clientId) => {
    return await API.instance.get(`/ucrm/client/${clientId}/services`);
  },
  deleteClient: async (clientId) => {
    return await API.instance.delete(`/ucrm/client/${clientId}`);
  },
};
